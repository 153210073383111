import { ICruise } from "../slices/searchSlice"; // Import ICruise interface from the searchSlice module
import { searchApi } from "./apiSingleton"; // Import searchApi from the apiSingleton module
import { store } from "../store"; // Import the store from the store module

// Define the structure of a location
export interface ILocation {
  type: "ship" | "country" | "cruise_tag";
  name: string;
  value: string;
}

// Define the response type for search results
type ISearchResultsResponse = ICruise[] | undefined;

// Define the request parameters for searching
export interface ISearchResultsRequest {
  search: string;
}

// Define the request parameters for fetching cruise details
interface ICruiseRequest {
  cruiseId: string;
  source: string;
}

// Define the response type for searching locations
interface ISearchLocationsResponse {
  auto_complete_items: Array<Record<string, string>>;
}

// Create SearchAPI by injecting endpoints for search-related APIs
export const SearchAPI = searchApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the endpoint for initializing search results
    initSearchResults: builder.query<
      ISearchResultsResponse, // Response data type
      ISearchResultsRequest // Request parameters type
    >({
      query: ({ search }) => {
        // Retrieve session key and API language from the store
        const { sessionKey } = store.getState().session;
        const { api_language } = store.getState().environment;

        // Construct the URL for fetching search results
        return {
          url: `/search/${search}&session=${sessionKey ?? ""}&language=${api_language ?? "en-us"}`,
          method: "GET",
        };
      },
    }),
    // Define the endpoint for initializing cruise details
    initCruise: builder.query<ICruise, ICruiseRequest>({
      query: ({ cruiseId, source }) => {
        // Retrieve session key and API language from the store
        const { sessionKey } = store.getState().session;
        const { api_language } = store.getState().environment;

        // Construct the URL for fetching cruise details
        return {
          url: `/search/${cruiseId}?session=${
            sessionKey ?? ""
          }&language=${api_language ?? "en-us"}&source=${source}`,
        };
      },
    }),
    // Define the endpoint for searching locations
    searchLocations: builder.query<ISearchLocationsResponse, unknown>({
      query: () => {
        // Retrieve session key and API language from the store
        const { sessionKey } = store.getState().session;
        const { api_language } = store.getState().environment;

        // Construct the URL for searching locations
        return {
          url: `/searchform/?session=${sessionKey ?? ""}&language=${api_language ?? "en-us"}`,
          method: "GET",
        };
      },
    }),
  }),
});

// Extract hooks from SearchAPI for using the defined queries
export const { useLazyInitCruiseQuery, useLazySearchLocationsQuery } =
  SearchAPI;
