// Import necessary modules from Redux Toolkit Query
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

// Import the Redux store
import { store } from "../store";

// Define a function to prepare headers for API requests
const prepareHeaders = (headers: Headers) => {
  // Get the token from the session state
  const { token } = store.getState().session;

  // If a token exists, set it in the Authorization header
  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }

  return headers;
};

// Define custom base query functions for different API domains
const sessionQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.token_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const posQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.pos_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const searchQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.search_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const agencyQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_DEV_API_URL as string,
  prepareHeaders: (headers) => {
    const { token } = store.getState().session;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
  },
});

const environmentQuery = fetchBaseQuery({
  baseUrl: `${window.location.protocol}//${window.location.hostname}`,
});

const paymentQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.payment_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const reservationQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.api_domain;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

// Create API clients for different services using createApi from Redux Toolkit Query
export const sessionApi = createApi({
  reducerPath: "SessionAPI",
  baseQuery: sessionQuery,
  endpoints: () => ({}),
});

export const posApi = createApi({
  reducerPath: "PosAPI",
  baseQuery: posQuery,
  endpoints: () => ({}),
});

export const searchApi = createApi({
  reducerPath: "SearchAPI",
  baseQuery: searchQuery,
  endpoints: () => ({}),
});

export const agencyApi = createApi({
  reducerPath: "AgencyAPI",
  baseQuery: agencyQuery,
  endpoints: () => ({}),
});

export const environmentApi = createApi({
  reducerPath: "EnvironmentAPI",
  baseQuery: environmentQuery,
  endpoints: () => ({}),
});

export const localesApi = createApi({
  reducerPath: "LocalesAPI",
  baseQuery: environmentQuery,
  endpoints: () => ({}),
});

export const paymentApi = createApi({
  reducerPath: "PaymentAPI",
  baseQuery: paymentQuery,
  endpoints: () => ({}),
});

export const reservationApi = createApi({
  reducerPath: "ReservationAPI",
  baseQuery: reservationQuery,
  endpoints: () => ({}),
});

// Bundle all APIs into a single object
const api = {
  session: sessionApi,
  pos: posApi,
  search: searchApi,
  agency: agencyApi,
  environment: environmentApi,
  locales: localesApi,
  payment: paymentApi,
  reservation: reservationApi,
};

// Export api
export default api;
