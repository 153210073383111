/**
 * Creates an array containing a range of numbers from start to end (inclusive).
 * @param {number} start - The starting number of the range.
 * @param {number} end - The ending number of the range.
 * @returns {number[]} An array containing numbers from start to end.
 */
export const createArrayRange = (start: number, end: number) => {
  const resultArray = [...Array(end - start + 1)].map(
    (_, index) => start + index,
  );

  return resultArray;
};
