import classNames from "classnames"; // Import classNames utility function
import { Link, To } from "react-router-dom"; // Import Link component and To type from React Router

import styles from "./index.module.scss"; // Import component styles

interface ICustomLinkProps {
  to: To; // Destination of the link
  isPrimary?: boolean; // Indicates if the link is primary
  isDisabled?: boolean; // Indicates if the link is disabled
  className?: string; // Custom class name for styling
}

/**
 * Renders a disabled link component.
 *
 * @param {boolean} isPrimary - Indicates if the link is primary.
 * @param {string} className - Custom class name for styling.
 * @param {ReactNode} children - Child elements.
 * @returns {JSX.Element} The rendered disabled link component.
 */

function DisabledLink({
  isPrimary,
  className,
  children,
}: React.PropsWithChildren<Pick<ICustomLinkProps, "isPrimary" | "className">>) {
  return (
    <span
      className={classNames(styles.container, className, {
        [styles.primary]: isPrimary,
      })}>
      {children}
    </span>
  );
}

/**
 * Renders a custom link component.
 *
 * @param {string | object} to - The destination of the link.
 * @param {boolean} isPrimary - Indicates if the link is primary.
 * @param {boolean} isDisabled - Indicates if the link is disabled.
 * @param {string} className - Custom class name for styling.
 * @param {ReactNode} children - Child elements.
 * @returns {JSX.Element} The rendered custom link component.
 */
function CustomLink({
  to,
  isPrimary,
  isDisabled,
  className,
  children,
}: React.PropsWithChildren<ICustomLinkProps>) {
  // If link is disabled, render DisabledLink
  if (isDisabled) {
    return (
      <DisabledLink isPrimary={isPrimary} className={className}>
        {children}
      </DisabledLink>
    );
  }

  // Otherwise, render a regular Link
  return (
    <Link
      to={to}
      className={classNames(styles.container, className, {
        [styles.primary]: isPrimary,
      })}>
      {children}
    </Link>
  );
}

// Default props for CustomLink component
CustomLink.defaultProps = {
  className: "",
  isPrimary: false,
  isDisabled: false,
};

// Export the CustomLink component
export default CustomLink;
