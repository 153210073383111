import { createSlice } from "@reduxjs/toolkit"; // Import necessary modules

import { InitLocalesResponse, LocalesAPI } from "../services/LocalesService"; // Import types and services

// Define initial state
const initialState: InitLocalesResponse = {
  languages: [],
};

// Create slice for managing toast state
const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setLanguages(state, action) {
      // Update the languages in the state with the payload data
      state.languages = action.payload.languages;
    },
  },
  extraReducers: (builder) => {
    // Add matcher to handle the fulfilled action from LocalesAPI
    builder.addMatcher(
      LocalesAPI.endpoints.initLocales.matchFulfilled,
      // Update the languages in the state with the payload data from the fulfilled action
      (state, action) => {
        state.languages = action.payload.languages;
      },
    );
  },
});

// Export action creators and reducer
export const { setLanguages } = toastSlice.actions;

export default toastSlice.reducer;
