/**
 * Generates a list of months.
 * @returns {string[]} An array containing the two-digit representations of months (01 for January, 02 for February, etc.).
 */
function getMonthsList() {
  const monthsList = [];

  for (let i = 0; i < 12; i++) {
    const month = String(i + 1).padStart(2, "0");

    monthsList.push(month);
  }

  return monthsList;
}

export default getMonthsList;
