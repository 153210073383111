import SvgIcon from "../SvgIcon"; // Import SvgIcon component from the specified path

import styles from "./index.module.scss"; // Import styles from the specified module

// Define props interface for the Counter component
interface ICounterProps {
  value: string;
  valueKey?: string; // Key for identifying the value
  errorMessage?: string;
  min?: number; // Minimum value allowed for the counter
  max?: number; // Maximum value allowed for the counter
  onChange: ({ value, valueKey }: { value: string; valueKey?: string }) => void;
}

/**
 * Counter component for handling numeric input with increment and decrement buttons.
 *
 * @param {string} value - Current value of the counter.
 * @param {string} valueKey - Key for identifying the value.
 * @param {string} errorMessage - Error message to display.
 * @param {number} [min=0] - Minimum value allowed for the counter.
 * @param {number} max - Maximum value allowed for the counter.
 * @param {Function} onChange - Change event handler.
 * @returns {JSX.Element} Counter component JSX.
 */
function Counter({
  value,
  valueKey,
  errorMessage,
  min,
  max,
  onChange,
}: ICounterProps) {
  // Function to handle input value change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check if the new value is within the specified range
    if (+e.target.value >= (min ?? 0) && +e.target.value <= (max ?? Infinity)) {
      onChange({ value: e.target.value, valueKey });
    }
  };

  // Function to handle button click (increase/decrease)
  const handleButtonClick = (action: "increase" | "decrease") => () => {
    let newValue = +value;

    switch (action) {
      case "increase": {
        newValue += 1;
        break;
      }

      default: {
        newValue -= 1;
        break;
      }
    }

    // Check if the new value is within the specified range
    if (newValue >= (min ?? 0) && newValue <= (max ?? Infinity)) {
      onChange({ value: `${newValue}`, valueKey });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.fieldContainer}>
        {/* Button to decrease value */}
        <button
          className={styles.button}
          onClick={handleButtonClick("decrease")}>
          <SvgIcon type="minus" />
        </button>

        {/* Input field for value */}
        <input
          className={styles.input}
          type="number"
          value={value}
          min={min}
          max={max}
          onChange={handleChange}
        />

        {/* Button to increase value */}
        <button
          className={styles.button}
          onClick={handleButtonClick("increase")}>
          <SvgIcon type="plus" />
        </button>
      </div>

      {/* Display error message if any */}
      <span className={styles.error}>{errorMessage}</span>
    </div>
  );
}

// Default props for the Counter component
Counter.defaultProps = {
  valueKey: "",
  errorMessage: "",
  min: 0,
  max: undefined,
};

// Export the Counter component
export default Counter;
