import { useMemo, useEffect, useState } from "react"; // Importing necessary hooks from React.
import { useNavigate, useParams, useSearchParams } from "react-router-dom"; // Importing hooks for navigation and URL parameters from React Router.
import { nanoid } from "@reduxjs/toolkit"; // Importing nanoid function from Redux Toolkit for generating unique IDs.
import { useTranslation } from "react-i18next"; // Importing hook for translation from react-i18next.

import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Importing typed dispatch and selector hooks from Redux store.

import {
  useLazyInitCabinsQuery,
  useLazyHoldCabinQuery,
  useLazyGetPricingQuery,
} from "../../../store/services/CabinSelectService"; // Importing lazy initialization hooks for cabin queries from cabin select service.

import { prepareParams } from "../../../utils/helpers/rooms"; // Importing helper function for preparing parameters.
import { Room, updateRoom } from "../../../store/slices/roomsSlice"; // Importing room interface and action creator from rooms slice.
import { showToast } from "../../../store/slices/toastSlice"; // Importing action creator for showing toast from toast slice.
import TOAST from "../../../utils/constants/toast"; // Importing constants for toast messages.

import LoadingContainer from "../../containers/LoadingContainer"; // Importing loading container component.
import Cabins from "./molecules/Cabins"; // Importing cabins component.
import Button from "../../shared/Button"; // Importing button component.
import DeckPlans from "./molecules/DeckPlans"; // Importing deck plans component.

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Defining the functional component CabinSelect.
function CabinSelect() {
  const dispatch = useTypedDispatch(); // Typed dispatch hook from Redux store.
  const navigate = useNavigate(); // Navigation hook from React Router.
  const [initRoomCabins] = useLazyInitCabinsQuery(); // Lazy initialization hook for initializing cabin query.
  const [holdRoomCabin] = useLazyHoldCabinQuery(); // Lazy initialization hook for holding cabin.
  const [getPricing] = useLazyGetPricingQuery(); // Lazy initialization hook for getting pricing.
  const { t } = useTranslation(); // Translation hook for i18n.

  const [searchParams] = useSearchParams(); // Retrieving search parameters from URL.
  const { cruiseId } = useParams(); // Retrieving cruise ID from URL parameters.

  const { cruise, isCruiseLoading } = useTypedSelector((state) => state.search); // Selecting cruise and loading state from Redux store.
  const { rooms, cabins } = useTypedSelector((state) => state.rooms); // Selecting rooms and cabins state from Redux store.

  const totalRooms = +(searchParams.get("rooms") ?? 0); // Total number of rooms from URL parameters.

  // Current room number from URL parameters.
  const currentRoomNumber = useMemo(
    () => +(searchParams.get("room") ?? 0),
    [searchParams.get("room")],
  );

  const [initCabinsKey, setInitCabinsKey] = useState(nanoid()); // State for initializing cabins key.
  const [isHoldCabinLoading, setIsHoldCabinLoading] = useState(false); // State for holding cabin loading.

  // Memoized room based on rooms and current room number.
  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }
  }, [rooms, currentRoomNumber]);

  // Memoized prepared decks based on cruise, room, and available decks.
  const preparedDecks = useMemo(() => {
    const output = cruise?.ship.decks
      .map((deck) => ({
        value: deck.code,
        label: deck.name,
      }))
      .filter((deck) =>
        room?.grade?.decks.map((el) => el.code).includes(deck.value),
      );

    return output ?? [];
  }, [room, cruise]);

  // Memoized boolean indicating whether user can proceed to the next step.
  const canGoWorward = useMemo(() => {
    return !!room?.cabin && cabins && cabins.length !== 0 && !!room?.deck;
  }, [room, cabins]);

  // Function to initialize cabins.
  const initCabins = () => {
    const guestsDobQs: string[] = [];

    // Constructing guest date of birth queries.
    for (let i = 1; i <= (room?.guestsNumber ?? 0); i++) {
      guestsDobQs.push(`guest_dob_${i}=1979-04-22`);
    }

    const source = cruise?.source ?? searchParams.get("source");

    // Initializing room cabins.
    initRoomCabins({
      cruiseId: cruiseId ?? "",
      rateCode: room?.fare?.rate_code ?? "",
      gradeCode: room?.grade?.code ?? "",
      guestCount: `${room?.guestsNumber ?? ""}`,
      guestsDobQs: guestsDobQs.join("&"),
      source,
    });
  };

  // Function to initialize chosen data.
  const initChosenData = () => {
    let isChanged = false;
    const updatedStateroom = structuredClone({ ...room });

    // Checking if cabin is chosen.
    if (cabins?.length && !room?.cabin) {
      updatedStateroom.cabin = cabins[0];
      isChanged = true;
    }

    // Checking if deck is chosen.
    if (cabins?.length && !room?.deck) {
      updatedStateroom.deck = cruise?.ship.decks.find(
        (deck) => deck.code === preparedDecks[0].value,
      );

      isChanged = true;
    }

    // Updating room if changes occurred.
    if (isChanged) {
      dispatch(
        updateRoom({ room: updatedStateroom, roomNumber: currentRoomNumber }),
      );
    }
  };

  // Function to handle choosing a cabin.
  const handleChose = (room: Room) => {
    dispatch(updateRoom({ room, roomNumber: currentRoomNumber }));
  };

  // Function to initialize pricing.
  const initPricing = async () => {
    // Setting loading state to true.
    setIsHoldCabinLoading(true);

    try {
      // Cloning room object.
      const clonedRoom: Room = structuredClone(room ?? {});
      const guestsDobQs: string[] = [];

      // Constructing guest date of birth queries.
      for (let i = 1; i <= (clonedRoom?.guestsNumber ?? 0); i++) {
        guestsDobQs.push(`guest_dob_${i}=1979-04-22`);
      }

      // Payload for pricing query.
      const payload = {
        sailing_code: cruise?.code ?? "",
        source: cruise?.source ?? "",
        rate_code: clonedRoom?.fare?.rate_code ?? "",
        grade_code: clonedRoom?.grade?.code ?? "",
        cabin_number: clonedRoom?.cabin?.number ?? "",
        guest_count: `${clonedRoom?.guestsNumber ?? 1}`,
        guests_dobs: guestsDobQs.join("&"),
      };

      // Getting pricing data.
      const response = await getPricing(payload);

      // Updating grade price if pricing data is available.
      if (response.data && clonedRoom.grade) {
        clonedRoom.grade.price = `${response.data.total_price}`;
      }

      // Updating room pricing data.
      if (response.data) {
        clonedRoom.pricing = clonedRoom.pricing ?? {};
        clonedRoom.pricing.payment_schedule = response.data.payment_schedule;
        clonedRoom.pricing.guests = response.data.guests;
        clonedRoom.pricing.total_price = response.data.total_price;
      }

      // Dispatching action to update room with pricing data.
      dispatch(updateRoom({ room: clonedRoom, roomNumber: currentRoomNumber }));

      return response;
    } catch (error) {
      // Handling errors during pricing initialization.
      console.error("initPricing error: ", error);
    } finally {
      // Setting loading state back to false.
      setIsHoldCabinLoading(false);
    }
  };

  // Function to hold cabin
  const holdCabin = async () => {
    // Setting loading state to true.
    setIsHoldCabinLoading(true);

    try {
      // Holding cabin using service.
      const response = await holdRoomCabin({
        cabin_number: room?.cabin?.number ?? "",
        grade_code: room?.grade?.code ?? "",
        rate_code: room?.fare?.rate_code ?? "",
        sailing_code: cruise?.code ?? "",
        agency: cruise?.source ?? "",
      });

      return response;
    } catch (error) {
      // Handling errors during cabin hold.
      console.error("holdCabin error: ", error);
    } finally {
      // Setting loading state back to false.
      setIsHoldCabinLoading(false);
    }
  };

  // Function to handle form submission.
  const handleSubmit = async () => {
    // Initializing pricing.
    const pricingResponse = await initPricing();
    // Holding cabin.
    const holdResponse = await holdCabin();

    // Determining next step based on current room number and total rooms.
    const pathParam =
      currentRoomNumber < totalRooms ? "cabin-select" : "guests";

    // Preparing parameters for next step.
    const params = prepareParams(
      searchParams,
      rooms ?? {},
      currentRoomNumber,
      currentRoomNumber < totalRooms,
    );

    // Checking if both operations were successful.
    const isSuccess = !!pricingResponse?.isSuccess && !!holdResponse?.isSuccess;

    // Navigating to next step if successful.
    if (isSuccess) {
      navigate(`/search-results/${cruiseId!}/${pathParam}?${params}`);
      // Resetting cabins key.
      setInitCabinsKey(nanoid());
    }

    // Showing error toast if either operation failed.
    if (!isSuccess) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Hold cabin error",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }
  };

  // Function to determine button label based on current step.
  const getButtonLabel = () => {
    // If more rooms to select, label indicates next room.
    if (currentRoomNumber < totalRooms) {
      return `${t("continue to stateroom")} ${currentRoomNumber + 1}`;
    }

    // If all rooms selected, label indicates proceeding to guests.
    return t("continue to guests");
  };

  // Effect to initialize chosen data when cabins change.
  useEffect(initChosenData, [cabins]);

  // Effect to initialize cabins.
  useEffect(() => {
    initCabins();
  }, [initCabinsKey]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <LoadingContainer isLoading={isCruiseLoading}>
      {/* Loading container while cruise data is loading. */}
      {/* Main container for cabin selection. */}
      <div className={styles.container}>
        {/* Content container. */}
        <div className={styles.content}>
          {/* Left section for stateroom selection. */}
          <div className={styles.left}>
            {/* Title for stateroom selection. */}
            <h1 className={styles.title}>{t("choose staterooms")}</h1>

            {/* Stateroom information container. */}
            <div className={styles.stateroomInfo}>
              {/* Subtitle indicating current stateroom number. */}
              <p className={styles.subtitle}>
                {t("stateroom")} {currentRoomNumber}
              </p>

              {/* Displaying stateroom grade name and description. */}
              <p className={styles.name}>
                {room?.grade?.name} -{" "}
                <span>{room?.grade?.descriptions?.[0].description ?? ""}</span>
              </p>

              {/* Displaying number of guests in stateroom. */}
              <p className={styles.name}>
                {room?.guestsNumber} {t("guests")}
              </p>
            </div>

            {/* Cabins component for selecting cabin. */}
            <Cabins
              cabins={cabins}
              room={room}
              handleChoseCabin={handleChose}
            />

            {/* Button container for proceeding to next step. */}
            <div className={styles.buttonContainer}>
              <Button
                id="cabins"
                className={styles.button}
                label={getButtonLabel()}
                onClick={handleSubmit}
                disabled={!canGoWorward} // Button disabled if cabin and deck not selected.
                loading={isHoldCabinLoading} // Button loading state while holding cabin.
              />
            </div>
          </div>

          {/* Deck plans component for selecting deck. */}
          <DeckPlans
            room={room}
            decks={preparedDecks}
            handleChoseDeck={handleChose}
          />
        </div>
      </div>
    </LoadingContainer>
  );
}

// Exporting CabinSelect component.
export default CabinSelect;
