import { ILayoutType } from "../../../utils/constants/routes"; // Importing the layout type interface.

import ToastContainer from "../../containers/ToastContainer"; // Importing the ToastContainer component for displaying toasts.
import ModalContainer from "../../containers/ModalContainer"; // Importing the ModalContainer component for handling modals.
import AuthLayout from "../Auth"; // Importing the AuthLayout component for authentication-related pages.
import SearchLayout from "../Search"; // Importing the SearchLayout component for search-related pages.
// import Header from "../../base/Header"; // Importing the Header component (commented out).
// import Footer from "../../base/Footer"; // Importing the Footer component (commented out).

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Mapping of layout types to their respective layout components.
const LAYOUTS_BY_TYPE: Record<
  ILayoutType,
  React.FC<React.PropsWithChildren>
> = {
  auth: AuthLayout,
  search: SearchLayout,
};

interface IRootLayoutProps {
  type: ILayoutType; // Props interface defining the type of layout.
}

function RootLayout({
  type,
  children,
}: React.PropsWithChildren<IRootLayoutProps>) {
  const Layout = LAYOUTS_BY_TYPE[type]; // Selecting the appropriate layout component based on the provided type.

  return (
    <ToastContainer>
      {/* Wrapping the entire layout in a ToastContainer */}
      <ModalContainer>
        {/* Wrapping the entire layout in a ModalContainer */}
        <div>
          {/* Header component (commented out) */}
          {/* <Header /> */}

          {/* Main content area with styling */}
          <main className={styles.container}>
            {/* Content section with styling */}
            <section className={styles.content}>
              {/* Rendering the selected layout component with nested children */}
              <Layout>{children}</Layout>
            </section>
          </main>

          {/* Footer component (commented out) */}
          {/* <Footer /> */}
        </div>
      </ModalContainer>
    </ToastContainer>
  );
}

// Exporting the RootLayout component for use in the application.
export default RootLayout;
