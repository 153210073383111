// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomLink_container__peCFz {
  text-decoration: none;
  color: var(--link-color);
}

.CustomLink_primary__hrLe5 {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/CustomLink/index.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,wBCaW;ADdb;;AAIA;EACE,gBAAA;AADF","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.container {\n  text-decoration: none;\n  color: $link-color;\n}\n\n.primary {\n  font-weight: 600;\n}\n","// COLORS\n$primary-color: var(--primary-color);\n$secondary-color: var(--secondary-color);\n$text-primary-color: var(--text-primary-color);\n$text-secondary-color: var(--text-secondary-color);\n$input-border-color: var(--input-border-color);\n$input-background-color: var(--input-background-color);\n$input-placeholder-color: var(--input-placeholder-color);\n$input-text-color: var(--input-text-color);\n$input-focus-border-color: var(--input-focus-border-color);\n$error-color: var(--error-color);\n$menu-background-color: var(--menu-background-color);\n$menu-text-color: var(--menu-text-color);\n$element-background-primary: var(--element-background-primary);\n$element-background-secondary: var(--element-background-secondary);\n$element-background-dark: var(--element-background-dark);\n$background-color: var(--background-color);\n$link-color: var(--link-color);\n$section-background-color: var(--section-background-color);\n$shadow-color: var(--shadow-color);\n$subtext-color: var(--subtext-color);\n$delimitter-line-color: var(--delimitter-line-color);\n$svg-icons-primary: var(--svg-icons-primary);\n$svg-icons-secondary: var(--svg-icons-secondary);\n$search-button-background: var(--search-button-background);\n\n// BREAK POINTS\n$mobile-sm-width: 320px;\n$mobile-base-width: 375px;\n$mobile-md-width: 576px;\n$mobile-lg-width: 768px;\n$tablet-width: 992px;\n$laptop-width: 1200px;\n$desktop-width: 1920px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CustomLink_container__peCFz`,
	"primary": `CustomLink_primary__hrLe5`
};
export default ___CSS_LOADER_EXPORT___;
