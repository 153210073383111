// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegalData_container__\\+P1To {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.LegalData_link__5LXEL {
  color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Payment/molecules/LegalData/index.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AADJ;;AAIA;EACI,2BCRY;ADOhB","sourcesContent":["@import \"../../../../../styles/variables.scss\";\n\n.container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.link {\n    color: $primary-color;\n}\n","// COLORS\n$primary-color: var(--primary-color);\n$secondary-color: var(--secondary-color);\n$text-primary-color: var(--text-primary-color);\n$text-secondary-color: var(--text-secondary-color);\n$input-border-color: var(--input-border-color);\n$input-background-color: var(--input-background-color);\n$input-placeholder-color: var(--input-placeholder-color);\n$input-text-color: var(--input-text-color);\n$input-focus-border-color: var(--input-focus-border-color);\n$error-color: var(--error-color);\n$menu-background-color: var(--menu-background-color);\n$menu-text-color: var(--menu-text-color);\n$element-background-primary: var(--element-background-primary);\n$element-background-secondary: var(--element-background-secondary);\n$element-background-dark: var(--element-background-dark);\n$background-color: var(--background-color);\n$link-color: var(--link-color);\n$section-background-color: var(--section-background-color);\n$shadow-color: var(--shadow-color);\n$subtext-color: var(--subtext-color);\n$delimitter-line-color: var(--delimitter-line-color);\n$svg-icons-primary: var(--svg-icons-primary);\n$svg-icons-secondary: var(--svg-icons-secondary);\n$search-button-background: var(--search-button-background);\n\n// BREAK POINTS\n$mobile-sm-width: 320px;\n$mobile-base-width: 375px;\n$mobile-md-width: 576px;\n$mobile-lg-width: 768px;\n$tablet-width: 992px;\n$laptop-width: 1200px;\n$desktop-width: 1920px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LegalData_container__+P1To`,
	"link": `LegalData_link__5LXEL`
};
export default ___CSS_LOADER_EXPORT___;
