import { paymentApi } from "./apiSingleton";
import { store } from "../store";

// Define the PaymentAPI with an endpoint to create opaque payments
export const PaymentAPI = paymentApi.injectEndpoints({
  endpoints: (builder) => ({
    createOpaquePayment: builder.query({
      query: ({ pnr, source, transactions }) => {
        // Extract session key from the store
        const { sessionKey: session } = store.getState().session;

        // Extract agency and payment path from the environment
        const { api_agency: agency, payment_api_path: payment_path } =
          store.getState().environment;

        // Return the request body
        return {
          url: payment_path, // Set the URL to the payment path
          method: "POST",
          body: {
            agency,
            pnr,
            session,
            source,
            transactions,
          },
        };
      },
    }),
  }),
});

// Extract the hook for creating opaque payments asynchronously
export const { useLazyCreateOpaquePaymentQuery } = PaymentAPI;
