// Import dependencies and components.
import { useTypedSelector } from "../../../../../store/store"; // Custom hook to access typed selector from the store.

import CustomCarousel from "../../../../shared/Carousel"; // Reusable component for carousel display.

import styles from "./index.module.scss"; // Styles specific to ImagesCarouselModal component.

// ImagesCarouselModal component responsible for rendering a modal displaying images in a carousel.
function ImagesCarouselModal() {
  // Retrieve data from the modal slice in the store using useTypedSelector hook.
  const { data } = useTypedSelector((state) => state.modal);

  // Render the ImagesCarouselModal component.
  return (
    <div className={styles.container}>
      {/* Render CustomCarousel component with images data as items, fallback to an empty array if data is null. */}
      <CustomCarousel items={(data as string[]) ?? []} />
    </div>
  );
}

// Export the ImagesCarouselModal component for use in other parts of the application.
export default ImagesCarouselModal;
