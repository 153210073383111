// Import necessary dependencies from React.
import React, { useState, useEffect } from "react"; // Hooks for managing component lifecycle and state.
import { useTranslation } from "react-i18next"; // Hook for internationalization (i18n).
import classNames from "classnames"; // Library for easily adding CSS classes.

// Import Redux hooks for accessing dispatch and selector functions.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";

// Import helper function for formatting currency.
import { currencyToFormat } from "../../../utils/helpers/currency";

// Import Redux actions and selectors related to search filtering.
import {
  sortName,
  sortDate,
  filterRangePrice,
  SORT_TYPE,
  sortPrice,
} from "../../../store/slices/searchFilterSlice";

// Import selectors from store.
import {
  selectSearchCalculatePrice,
  selectSearchFilter,
} from "../../../store/selectors";

// Import shared components.
import Button from "../../shared/Button";
import RangeSlider from "../../shared/RangeSlider";

// Import styles for the component.
import styles from "./index.module.scss";

// Component SearchFilterForm renders the filtering options for search results.
function SearchFilterForm() {
  const dispatch = useTypedDispatch(); // Redux dispatch function.

  const { t } = useTranslation(); // Initialize i18n hook.
  const filter = useTypedSelector(selectSearchFilter); // Retrieve search filter state from Redux store.
  const filterPrices = useTypedSelector(selectSearchCalculatePrice); // Retrieve calculated prices for filtering from Redux store.
  const { rooms } = useTypedSelector((state) => state.rooms); // Retrieve room data from Redux store.

  const [rangePrice, setRangePrice] = React.useState([...filterPrices]); // State to manage price range for filtering.

  // Handler for sorting results by name.
  const handleSortName = () => {
    dispatch(sortName(!filter.sortName));
  };

  // Handler for sorting results by date.
  const handleSortDate = () => {
    dispatch(sortDate(!filter.sortDate));
  };

  // Handler for sorting results by price.
  const handleSortPrice = () => {
    dispatch(sortPrice(!filter.sortPrice));
  };

  // Handler for updating price range filter.
  const handleRangePrice = (minPrice: number, maxPrice: number) => {
    dispatch(filterRangePrice([minPrice, maxPrice]));
  };

  // Update price range filter when filter prices change.
  useEffect(() => {
    handleRangePrice(filterPrices[0], filterPrices[1]);
  }, [filterPrices]);

  // Render the SearchFilterForm component.
  return (
    <div className={classNames(styles.content)}>
      {/* Range slider for adjusting price range */}
      <div className={classNames(styles.rangeSliderContainer)}>
        <p className={classNames(styles.labelRangePrice)}>
          {/* Display price range based on current filter */}
          {currencyToFormat(
            rangePrice[0],
            rooms?.[1]?.pricing?.payment_schedule?.[0]?.currency ?? "USD",
          )}{" "}
          -{" "}
          {currencyToFormat(
            rangePrice[1],
            rooms?.[1]?.pricing?.payment_schedule?.[0]?.currency ?? "USD",
          )}
        </p>

        {/* Range slider component */}
        <RangeSlider
          min={filterPrices[0]}
          max={filterPrices[1]}
          onMouseUp={(value) => handleRangePrice(value.min, value.max)}
          onChange={(value) => setRangePrice([value.min, value.max])}
        />
      </div>

      {/* Button for sorting results by name */}
      <Button
        className={classNames(styles.button, {
          [styles.activeButton]: filter.sortType === SORT_TYPE.NANE,
        })}
        label={t("sort name: ") + (filter.sortName ? "A-Z" : "Z-A")}
        icon={filter.sortName ? "down" : "up"}
        onClick={handleSortName}
      />

      {/* Button for sorting results by date */}
      <Button
        className={classNames(styles.button, {
          [styles.activeButton]: filter.sortType === SORT_TYPE.DATE,
        })}
        label={t("sort date")}
        icon={filter.sortDate ? "down" : "up"}
        onClick={handleSortDate}
      />

      {/* Button for sorting results by price */}
      <Button
        className={classNames(styles.button, {
          [styles.activeButton]: filter.sortType === SORT_TYPE.PRICE,
        })}
        label={t("sort price")}
        icon={filter.sortPrice ? "down" : "up"}
        onClick={handleSortPrice}
      />
    </div>
  );
}

// Component SearchFilter renders the filter button and search filter form.
function SearchFilter() {
  const { t } = useTranslation(); // Initialize i18n hook.

  const [showFilter, setShowFilter] = useState(false); // State to manage visibility of filter form.

  // Handler for toggling visibility of filter form
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  // Render the SearchFilter component.
  return (
    <div className={classNames(styles.container)}>
      {/* Button to show/hide filter form */}
      <Button label={t("filter results")} onClick={handleShowFilter} />

      {/* Render filter form if showFilter is true */}
      {showFilter && <SearchFilterForm />}
    </div>
  );
}

// Export the SearchFilter component for use in other parts of the application.
export default SearchFilter;
