import { useEffect, useMemo } from "react"; // Importing necessary hooks from React
import { useTranslation } from "react-i18next"; // Importing the useTranslation hook for language translation
import { useNavigate, useParams, useSearchParams } from "react-router-dom"; // Importing necessary hooks from react-router-dom

import MODAL from "../../../utils/constants/modal"; // Importing constants for modal types
import { Grade } from "../../../store/slices/searchSlice"; // Import Grade type
import { showModal } from "../../../store/slices/modalSlice"; // Importing action creators from Redux slices
import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Importing custom hooks from the Redux store
import { prepareParams } from "../../../utils/helpers/rooms"; // Importing helper function for preparing parameters
import { Room, updateRoom } from "../../../store/slices/roomsSlice"; // Importing Room related types and action creators from Redux slices
import { setPricing } from "../../../store/slices/pricingSlice"; // Importing action creator for updating pricing information

import LoadingContainer from "../../containers/LoadingContainer"; // Importing the LoadingContainer component
import Button from "../../shared/Button"; // Importing the Button component

// Importing sub-components for Rooms component
import CompletedStaterooms from "./molecules/CompletedStaterooms";
import FareCodes from "./molecules/FareCodes";
import StateroomsMarkets from "./molecules/StateroomsMarkets";
import StateroomsList from "./molecules/StateroomsList";

import styles from "./index.module.scss"; // Importing styles for the Rooms component

// Functional component for managing room selection
function Rooms() {
  const dispatch = useTypedDispatch(); // Accessing the dispatch function from useTypedDispatch
  const navigate = useNavigate(); // Accessing the navigate function from useNavigate
  const { t } = useTranslation(); // Accessing the translation function from useTranslation

  const [searchParams] = useSearchParams(); // Accessing search parameters from the URL
  const { cruiseId } = useParams(); // Extracting cruiseId from URL params

  const { isCruiseLoading } = useTypedSelector((state) => state.search); // Accessing loading status from Redux store
  const { rooms } = useTypedSelector((state) => state.rooms); // Accessing rooms data from Redux store
  const { show_pricing_breakdown } = useTypedSelector(
    (state) => state.environment,
  ); // Accessing environment data from Redux store

  // Extracting total rooms and current room number from URL params
  const totalRooms = +(searchParams.get("rooms") ?? 0);
  const currentRoomNumber = +(searchParams.get("room") ?? 0);

  // Memoized room object based on current room number
  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }
  }, [rooms, currentRoomNumber]);

  // Memoized boolean indicating whether forward navigation is allowed
  const canGoForward = useMemo(() => {
    return !!room?.grade && !!room?.guestsNumber;
  }, [room]);

  // Function to update the state of a room
  const updateStateRoom = (stateroom: Room) => {
    dispatch(updateRoom({ room: stateroom, roomNumber: currentRoomNumber }));
  };

  // Function to handle showing the itinerary modal
  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  // Function to handle showing images modal
  const handleShowImagesModal = (images: string[]) => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.IMAGES, data: images }));
  };

  // Function to handle showing staterooms modal
  const handleShowStateroomsModal = (grade: Grade) => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.STATEROOMS, data: grade }));
  };

  // Function to handle form submission
  const handleSubmit = () => {
    // Determine next path parameter based on current room number
    const pathParam = currentRoomNumber < totalRooms ? "rooms" : "cabin-select";

    // Prepare parameters for the next URL
    const params = prepareParams(
      searchParams,
      rooms ?? {},
      currentRoomNumber,
      currentRoomNumber < totalRooms,
    );

    // Navigate to the next URL with updated parameters
    navigate(`/search-results/${cruiseId!}/${pathParam}?${params}`);
  };

  // Function to get the label for the button based on current room number
  const getButtonLabel = () => {
    if (currentRoomNumber < totalRooms) {
      return `${t("continue to stateroom")} ${currentRoomNumber + 1}`;
    }

    return t("continue to staterooms");
  };

  // Effect to update pricing information when rooms change
  useEffect(() => {
    dispatch(setPricing(rooms));
  }, [rooms]);

  // Effect to scroll to the top of the page on component mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    // Render the LoadingContainer with loading status
    <LoadingContainer isLoading={isCruiseLoading || !rooms || !room}>
      {/* Main container for the Rooms component */}
      <div className={styles.container}>
        <div className={styles.content}>
          {/* Left section containing stateroom selection details */}
          <div className={styles.left}>
            {/* Title for stateroom selection */}
            <h1 className={styles.title}>{t("choose staterooms")}</h1>

            {/* Subtitle indicating current stateroom number */}
            <p className={styles.subtitle}>
              {t("stateroom")} {currentRoomNumber}
            </p>

            {/* Fare codes selection component */}
            <FareCodes room={room} handleChoseRate={updateStateRoom} />

            {/* Staterooms markets selection component */}
            <StateroomsMarkets
              room={room}
              handleChoseMarket={updateStateRoom}
            />

            {/* Staterooms list component */}
            <StateroomsList
              room={room}
              handleChoseStateroom={updateStateRoom}
              handleShowImagesModal={handleShowImagesModal}
              handleShowStateroomsModal={handleShowStateroomsModal}
            />

            {/* Container for the continue button */}
            <div className={styles.buttonContainer}>
              {/* Render the Button component */}
              <Button
                className={styles.button}
                label={getButtonLabel()}
                onClick={handleSubmit}
                disabled={!canGoForward}
                id="continue"
              />
            </div>
          </div>

          {/* Right section containing additional options */}
          <div className={styles.right}>
            {/* Button to view itinerary */}
            <Button
              label={t("view itinerary")}
              variant="secondary"
              icon="plus"
              onClick={handleShowItineraryModal}
            />

            {/* Completed staterooms component */}
            <CompletedStaterooms />

            {/* Render the continue button again if pricing breakdown is shown */}
            {show_pricing_breakdown && (
              <Button
                className={styles.button}
                label={getButtonLabel()}
                onClick={handleSubmit}
                disabled={!canGoForward}
                id="continue-2"
              />
            )}
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

// Exporting the Rooms component as default.
export default Rooms;
