import { useTranslation } from "react-i18next"; // Importing the useTranslation hook from react-i18next library.

import { useTypedSelector } from "../../../../../store/store"; // Importing the useTypedSelector hook from the store.
import { Room } from "../../../../../store/slices/roomsSlice"; // Importing the Room type from the roomsSlice slice.
import { Deck } from "../../../../../store/slices/searchSlice"; // Importing the Deck type from the searchSlice slice.

import LoadingContainer from "../../../../containers/LoadingContainer"; // Importing the LoadingContainer component from the containers directory.
import CustomSelect from "../../../../shared/CustomSelect"; // Importing the CustomSelect component from the shared directory.

import styles from "./index.module.scss"; // Importing the local CSS module for styling.

// Defining the interface for the DeckPlans component props.
interface IDeckPlansProps {
  room?: Room; // Optional property for the room information.
  decks?: Array<{ value: string; label: string }>; // Optional array of deck options.
  handleChoseDeck: (room: Room) => void; // Function to handle deck selection.
}

// Defining the DeckPlans functional component with props.
function DeckPlans({ room, decks, handleChoseDeck }: IDeckPlansProps) {
  const { t } = useTranslation(); // Using the useTranslation hook to access translation functions.
  const { cruise } = useTypedSelector((state) => state.search); // Extracting cruise information from the store using useTypedSelector.
  const { isCabinsLoading, cabins } = useTypedSelector((state) => state.rooms); // Extracting loading state and cabin information from the store.

  // Function to handle deck selection change.
  const handleDeckChange = ({ value }: { value: string }) => {
    // Finding the selected deck based on its code.
    const deck: Deck | undefined = cruise?.ship.decks.find(
      // Matching the deck code with the selected value.
      (el) => el.code === value,
    );

    // Creating a deep copy of the room with the updated deck.
    const updatedStateroom = structuredClone({ ...room, deck });

    // Calling the handleChoseDeck function with the updated room.
    handleChoseDeck(updatedStateroom);
  };

  return (
    <LoadingContainer isLoading={isCabinsLoading}>
      {/* Rendering the LoadingContainer component with loading state. */}
      {/* Container div with styles from local CSS module. */}
      <div className={styles.container}>
        {/* Paragraph displaying the translated text for "deck plans". */}
        <p className={styles.title}>{t("deck plans")}</p>

        {cabins?.length ? ( // Conditional rendering based on cabin data existence.
          <div className={styles.selectContainer}>
            {/* Container for the select dropdown and deck image. */}
            {/* CustomSelect component for selecting a deck. */}
            <CustomSelect
              className={styles.select}
              label={t("choose a deck")} // Label for the select dropdown.
              placeholder={t("select a deck")} // Placeholder text for the select dropdown.
              value={room?.deck?.code ?? ""} // Current selected deck value.
              items={decks ?? []} // Deck options for the select dropdown.
              onChange={handleDeckChange}
            />

            {/* Image displaying the selected deck. */}
            <img src={room?.deck?.images[0] ?? ""} alt={t("deck")} />
          </div>
        ) : (
          <p className={styles.dataInfo}>{t("No data to display")}</p>
        )}
      </div>
    </LoadingContainer>
  );
}

// Exporting the DeckPlans component as default.
export default DeckPlans;
