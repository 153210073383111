import { localesApi } from "./apiSingleton"; // import api to make request

// Define interface form locales response
export interface InitLocalesResponse {
  languages: Array<{
    origin: string;
    language: Array<{ original: string; translation: string }>;
  }>;
}

// Define the LocalesAPI with an endpoint to initialize locales
export const LocalesAPI = localesApi.injectEndpoints({
  endpoints: (builder) => ({
    initLocales: builder.query<InitLocalesResponse, null>({
      query: () => ({
        url: "/wp-json/acf/v3/options/options/languages",
        method: "GET",
      }),
    }),
  }),
});

// Extract the hook for initiating locales
export const { useLazyInitLocalesQuery } = LocalesAPI;
