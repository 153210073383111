import { createSlice } from "@reduxjs/toolkit"; // Import necessary modules

import { ReservationApi } from "../services/ReservationService"; // Import service api

// Define interfaces for reservation object
export interface ReservationGuestPricingInterface {
  category: string;
  extras: string;
  fare: string;
  gft: string;
  guest: number;
  total_fare: string;
  total_price: string;
}

export interface ReservationGuestInterface {
  address: string[];
  city: string;
  country: string;
  date_of_birth: string;
  documentation: [];
  email: string;
  external_code: string | null;
  extras: [];
  gender: string;
  given_name: string;
  id: number;
  language: string;
  lastname: string;
  lead_passenger: boolean;
  nationality: string;
  passenger_type: string;
  phone: string;
  pricing: ReservationGuestPricingInterface;
  request: string;
  state: string;
  title: string;
  uuid: string;
  zip_code: string;
}

export interface ReservationCabinsInterface {
  guests: ReservationGuestInterface[];
  cabin_number: string;
  uuid: string;
  grade_code: string;
  deck_name: string;
}

export interface ReservationItineraryInterface {
  arrive_time: string;
  date: string;
  depart_time: string;
  extra_information: string | null;
  port_code: string;
  port_country: string;
  port_name: string;
  tender: boolean;
}

export interface ReservationSailingsInterface {
  cabins: ReservationCabinsInterface[];
  commission: string;
  consortia_commission: string;
  cruise_code: string;
  cruise_duration: number;
  cruise_name: string;
  currency: string;
  embarkation_date: string;
  external_rate_code: string | null;
  external_sailing_code: string;
  extras_price: string;
  fare: string;
  itinerary: ReservationItineraryInterface[];
  price: string;
  rate_code: string;
  rate_name: string;
  sailing_code: string;
  ship_code: string;
  ship_name: string;
}

export interface ReservationPaymentScheduleInterface {
  amount: string;
  currency: string;
  due_date: string;
}

export interface TransactionInterface {
  id: number;
  currency: string;
  amount: string;
  made_on: string;
  made_by: string;
  transaction_type: string;
  card_clearance: {
    other: {
      card_type: string;
      last_four: string;
    };
    auth_code: string;
    transaction_id: string;
    clearance_system: string;
  };
  refunded: boolean;
}

export interface ReservationInterface {
  pnr: string;
  lead_given_name: string;
  embarkation_date: string;
  cruise_name: string;
  created: string;
  total_price?: string;
  lead_email?: string;
  lead_lastname?: string;
  lead_phone?: string;
  lead_title?: string;
  sailings: ReservationSailingsInterface[];
  payment_schedule: ReservationPaymentScheduleInterface[];
  market: string;
  commission: string;
  next_payment_outstanding: string;
  next_payment_due: string;
  transactions: TransactionInterface[];
}

export interface ReservationState {
  reservation: ReservationInterface;
}

const initialState: ReservationState = {
  reservation: {
    pnr: "",
    lead_given_name: "",
    embarkation_date: "",
    cruise_name: "",
    created: "",
    total_price: "",
    lead_email: "",
    lead_lastname: "",
    lead_phone: "",
    lead_title: "",
    sailings: [
      {
        cabins: [
          {
            guests: [
              {
                address: [""],
                city: "",
                country: "",
                date_of_birth: "",
                documentation: [],
                email: "",
                external_code: "",
                extras: [],
                gender: "",
                given_name: "",
                id: 0,
                language: "",
                lastname: "",
                lead_passenger: false,
                nationality: "",
                passenger_type: "",
                phone: "",
                pricing: {
                  category: "",
                  extras: "",
                  fare: "",
                  gft: "",
                  guest: 0,
                  total_fare: "",
                  total_price: "",
                },
                request: "",
                state: "",
                title: "",
                uuid: "",
                zip_code: "",
              },
            ],
            cabin_number: "",
            uuid: "",
            grade_code: "",
            deck_name: "",
          },
        ],
        commission: "",
        consortia_commission: "",
        cruise_code: "",
        cruise_duration: 0,
        cruise_name: "",
        currency: "",
        embarkation_date: "",
        external_rate_code: "",
        external_sailing_code: "",
        extras_price: "",
        fare: "",
        itinerary: [],
        price: "",
        rate_code: "",
        rate_name: "",
        sailing_code: "",
        ship_code: "",
        ship_name: "",
      },
    ],
    payment_schedule: [{ amount: "", currency: "", due_date: "" }],
    market: "",
    commission: "",
    next_payment_outstanding: "",
    next_payment_due: "",
    transactions: [],
  },
};

// Create slice for managing reservation state
const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      // Add a matcher for matching the fulfilled action of the retrieveReservation endpoint
      ReservationApi.endpoints.retrieveReservation.matchFulfilled,
      (state, action) => {
        // When the action is fulfilled, update the reservation state with the payload
        state.reservation = action.payload;
      },
    );
  },
});

// Export the reducer function for reservation slice
export default reservationSlice.reducer;
