// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flatpickr-months {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.flatpickr-months .flatpickr-month {
  height: 46px;
}

.flatpickr-current-month {
  height: 100%;
  padding: 10px 0;
}

.flatpickr-day.selected,
.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.flatpickr-monthSelect-month.flatpickr-disabled {
  color: var(--secondary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/DatePicker/index.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AADF;AAGE;;EAEE,QAAA;EACA,6BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;;AAMA;EACE,YAAA;EACA,eAAA;AAHF;;AAMA;;;;EAIE,2CAAA;EACA,6CAAA;AAHF;;AAMA;EACE,wCAAA;AAHF","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.flatpickr-months {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n\n  .flatpickr-prev-month,\n  .flatpickr-next-month {\n    top: 50%;\n    transform: translate(0, -50%);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .flatpickr-month {\n    height: 46px;\n  }\n}\n\n.flatpickr-current-month {\n  height: 100%;\n  padding: 10px 0;\n}\n\n.flatpickr-day.selected,\n.flatpickr-monthSelect-month.selected,\n.flatpickr-monthSelect-month.startRange,\n.flatpickr-monthSelect-month.endRange {\n  background: $primary-color !important;\n  border-color: $primary-color !important;\n}\n\n.flatpickr-monthSelect-month.flatpickr-disabled {\n  color: $secondary-color !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
