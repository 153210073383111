import search from "./search";
import rooms from "./rooms";
import guestForm from "./guestForm";

// Define all rules
const rules = {
  search,
  rooms,
  guestForm,
};

export default rules;
