// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_container__wsUu9 {
  height: unset;
}

.Carousel_slide__o48Io {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/Carousel/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;EACA,uBAAA;AACF","sourcesContent":[".container {\n  height: unset;\n}\n\n.slide {\n  width: 100%;\n  height: 350px;\n  object-fit: cover;\n  object-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Carousel_container__wsUu9`,
	"slide": `Carousel_slide__o48Io`
};
export default ___CSS_LOADER_EXPORT___;
