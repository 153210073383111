import classNames from "classnames"; // Library for conditionally joining classNames together.

import styles from "./index.module.scss"; // Styles specific to the Toast component.

// Interface defining the props for the Toast component.
interface IToastProps {
  isVisible: boolean; // Flag indicating whether the toast is visible.
  message: string; // Content of the toast message.
  type: string; // Type of the toast (e.g., "success", "error").
}

// Toast component responsible for rendering toast notifications.
function Toast({ isVisible, message, type }: IToastProps) {
  return (
    <div
      className={classNames(styles.container, styles[`container_${type}`], {
        [styles.container_visible]: isVisible, // Conditionally add 'container_visible' class if isVisible is true.
      })}>
      {/* Display the message content of the toast. */}
      <span className={styles.message}>{message}</span>
    </div>
  );
}

// Export the Toast component for use in other parts of the application.
export default Toast;
