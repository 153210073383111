// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-item .page-link {
  color: var(--primary-color);
}

.page-item.active .page-link {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.more-button {
  width: 100%;
  height: 70px;
  border: 0;
  text-align: center;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--text-secondary-color);
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 600;
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  will-change: box-shadow, background-color, opacity;
  font-size: 16px !important;
  line-height: 24px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/Pagination/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gCAAgC;EAChC,kCAAkC;EAClC,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,eAAe;EACf,kCAAkC;EAClC,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB;;;4BAG0B;EAC1B,kDAAkD;EAClD,0BAA0B;EAC1B,4BAA4B;AAC9B","sourcesContent":[".page-item .page-link {\n  color: var(--primary-color);\n}\n\n.page-item.active .page-link {\n  background: var(--primary-color);\n  border-color: var(--primary-color);\n  color: white;\n}\n\n.more-button {\n  width: 100%;\n  height: 70px;\n  border: 0;\n  text-align: center;\n  outline: 0;\n  text-transform: uppercase;\n  cursor: pointer;\n  color: var(--text-secondary-color);\n  background-color: var(--primary-color);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  white-space: nowrap;\n  font-weight: 600;\n  transition:\n    box-shadow 0.3s ease-in-out,\n    background-color 0.3s ease-in-out,\n    opacity 0.3s ease-in-out;\n  will-change: box-shadow, background-color, opacity;\n  font-size: 16px !important;\n  line-height: 24px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
