// Importing necessary dependencies from react-router-dom for routing in the application.
import {
  HashRouter as Router, // Using HashRouter for routing with hash fragments in the URL.
  Navigate, // Component for navigating to a different route.
  Route, // Component for defining routes.
  Routes, // Component for grouping multiple routes.
} from "react-router-dom";

// Importing the router configuration from constants for defining application routes.
import router from "./utils/constants/router";

// Importing route constants for redirecting to the root route.
import { ROOT } from "./utils/constants/routes";

// Importing guard and layout components for route protection and layout rendering.
import RootGuard from "./components/guards/RootGuard";
import RootLayout from "./components/layouts/Root";

// Navigation component responsible for rendering routes and layout components.
function Navigation() {
  return (
    // Wrapping the navigation with RootGuard for route protection.
    <RootGuard>
      {/* Setting up the router component. */}
      <Router>
        {/* Defining routes using Routes component. */}
        <Routes>
          {/* Mapping over router configuration to generate Route components dynamically. */}
          {Object.entries(router).map(([routeKey, route]) => {
            // Destructuring route object to extract element and layout.
            const { element: Element, layout } = route;

            return (
              // Rendering Route component for each route, with key, path, and nested layout and element components.
              <Route
                key={routeKey}
                path={routeKey}
                element={
                  // Wrapping route element with RootLayout for consistent layout rendering.
                  <RootLayout type={layout}>
                    <Element />
                  </RootLayout>
                }
              />
            );
          })}

          {/* Catch-all route to redirect to the root route if no matching route is found. */}
          <Route path="*" element={<Navigate to={ROOT} />} />
        </Routes>
      </Router>
    </RootGuard>
  );
}

// Exporting Navigation component.
export default Navigation;
