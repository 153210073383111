import { reservationApi } from "./apiSingleton"; // Import the reservationApi from the apiSingleton module
import { ReservationInterface } from "../slices/reservationSlice"; // Import the ReservationInterface from the reservationSlice module

// Create ReservationApi by injecting endpoints for reservation API
export const ReservationApi = reservationApi.injectEndpoints({
  endpoints: (builder) => ({
    // Define the endpoint for retrieving reservations
    retrieveReservation: builder.query<
      ReservationInterface, // Response data type
      { pnr: string; agency: string } // Request parameters type
    >({
      query: (params) => ({
        url: `/res/reservation-list/${params.pnr}/?agency=${params.agency}`, // Endpoint URL with parameters
        method: "GET", // HTTP method
      }),
    }),
  }),
});

// Extract the useLazyRetrieveReservationQuery hook from the ReservationApi
export const { useLazyRetrieveReservationQuery } = ReservationApi;
