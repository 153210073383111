// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoomForms_container__PNhuV {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Passengers/molecules/RoomForms/index.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RoomForms_container__PNhuV`
};
export default ___CSS_LOADER_EXPORT___;
