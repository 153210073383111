// Import LoadingIndicator component for displaying loading animation.
import LoadingIndicator from "../../shared/LoadingIndicator";

// Import styles for the component.
import styles from "./index.module.scss";

// Define the props interface for LoadingContainer component.
interface ILoadingContainerProps {
  isLoading: boolean; // Boolean flag indicating whether to display loading indicator.
}

// LoadingContainer component renders a container that conditionally displays a loading indicator.
function LoadingContainer({
  isLoading, // Boolean flag indicating whether to display loading indicator.
  children, // Child components to be rendered within the container.
}: React.PropsWithChildren<ILoadingContainerProps>) {
  // If isLoading is true, render the loading indicator within the container.
  if (isLoading) {
    return (
      <div className={styles.container}>
        <LoadingIndicator />
      </div>
    );
  }

  // If isLoading is false, render the children components within the container.
  return <>{children}</>; // Render the children components.
}

// Export the LoadingContainer component for use in other parts of the application.
export default LoadingContainer;
