// Import necessary dependencies and components.
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Custom hooks for typed dispatch and selector
import useOnClickOutside from "../../../hooks/useClickOutside"; // Custom hook to handle click events outside a component.
import { hideModal } from "../../../store/slices/modalSlice"; // Action creator to hide the modal.
import MODAL from "../../../utils/constants/modal"; // Constants related to modal types.

import SvgIcon from "../../shared/SvgIcon"; // Reusable component for SVG icons.
import ItineraryModal from "./modals/ItineraryModal"; // Specific modal component for itinerary.
import ImagesCarouselModal from "./modals/ImagesCarouselModal"; // Specific modal component for image carousel.
import StateroomsModal from "./modals/StateroomsModal"; // Specific modal component for staterooms.

import styles from "./index.module.scss"; // Styles specific to ModalContainer component.

// Object mapping modal types to their respective modal components.
const MODALS_BY_TYPE = {
  [MODAL.MODAL_TYPES.ITINERARY]: ItineraryModal,
  [MODAL.MODAL_TYPES.IMAGES]: ImagesCarouselModal,
  [MODAL.MODAL_TYPES.STATEROOMS]: StateroomsModal,
};

// Modal component responsible for rendering specific modal content based on the provided type.
function Modal({ type }: { type: string }) {
  const Component = MODALS_BY_TYPE[type] ?? "div"; // Retrieve the component based on the type, fallback to a div if not found.

  return <Component />; // Render the modal component.
}

// ModalContainer component manages the display of modal content and handles closing of modals.
function ModalContainer({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch(); // Typed dispatch function from the store.

  const { type } = useTypedSelector((state) => state.modal); // Retrieve modal type from the store.

  const [internalType, setInternalType] = useState(type); // Internal state to manage modal type transitions.

  const outsideRef = useRef(null); // Ref for detecting clicks outside the modal container.

  // Function to close the modal.
  const handleCloseModal = () => {
    dispatch(hideModal()); // Dispatch action to hide the modal.
  };

  // Effect to update internalType when modal type changes.
  useEffect(() => {
    // Delay setting internalType to allow CSS transition.
    setTimeout(
      () => {
        setInternalType(type);
      },
      type ? 0 : 300,
    ); // If type is null (modal is closing), delay the transition for 300ms.
  }, [type]);

  // Hook to handle clicks outside the modal content.
  useOnClickOutside(outsideRef, handleCloseModal);

  // Render the ModalContainer component.
  return (
    <div
      className={classNames(styles.container, {
        [styles.container_active]: internalType, // Add 'container_active' class if internalType is truthy.
      })}>
      {children} {/* Render children components. */}
      {/* Render the modal overlay and content. */}
      <div
        className={classNames(styles.modal, {
          [styles.modal_active]: internalType, // Add 'modal_active' class if internalType is truthy.
        })}>
        <div
          ref={outsideRef}
          className={classNames(styles.content, {
            [styles.content_active]: type, // Add 'content_active' class if type is truthy.
          })}>
          {/* Render modal header with title and close icon. */}
          <div className={styles.header}>
            <span className={styles.headerTitle}>{internalType}</span>

            <SvgIcon
              type="plus"
              className={styles.closeIcon}
              onClick={handleCloseModal} // Attach onClick event to close the modal
            />
          </div>

          {/* Render modal content based on internalType. */}
          <Modal type={internalType} />
        </div>
      </div>
    </div>
  );
}

// Export the ModalContainer component for use in other parts of the application.
export default ModalContainer;
