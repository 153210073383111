/**
 * Formats a numeric value as currency in USD.
 * @param {number} value - The numeric value to format as currency.
 * @returns {string} The formatted currency string.
 */
const formatCurrency = (value: number) => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return formattedValue;
};

export default formatCurrency;
