import { useTypedSelector } from "../../../../../store/store"; // Importing the useTypedSelector hook from the Redux store

import Checkbox from "../../../../shared/Checkbox"; // Importing the Checkbox component

import styles from "./index.module.scss"; // Importing the CSS module for styling

// Interface for props of the LegalData component
interface ILegalDataProps {
  terms_and_conditions_checked: boolean; // Whether terms and conditions are checked
  participant_release_agreement_checked: boolean; // Whether participant release agreement is checked
  supplemental_terms_checked: boolean; // Whether supplemental terms are checked
  guest_ticket_contract_checked: boolean; // Whether guest ticket contract is checked
  show_cancellation_policy_checked: boolean; // Whether show cancellation policy is checked
  onChange: ({ value, valueKey }: { value: boolean; valueKey: string }) => void; // Function to handle changes in checkbox values
}

/**
 * Functional component for rendering legal data checkboxes.
 * @component
 * @param {boolean} terms_and_conditions_checked - Whether terms and conditions are checked.
 * @param {boolean} participant_release_agreement_checked - Whether participant release agreement is checked.
 * @param {boolean} supplemental_terms_checked - Whether supplemental terms are checked.
 * @param {boolean} guest_ticket_contract_checked - Whether guest ticket contract is checked.
 * @param {boolean} show_cancellation_policy_checked - Whether show cancellation policy is checked.
 * @param {Function} onChange - Function to handle changes in checkbox values.
 * @returns {JSX.Element} LegalData component JSX structure.
 */
function LegalData({
  terms_and_conditions_checked,
  participant_release_agreement_checked,
  supplemental_terms_checked,
  guest_ticket_contract_checked,
  show_cancellation_policy_checked,
  onChange,
}: ILegalDataProps) {
  // Using the useTypedSelector hook to access state values from the Redux store
  const {
    privacy_policy,
    show_cancellation_policy,
    terms_and_conditions,
    participant_release_agreement,
    supplemental_terms,
    guest_ticket_contract,
  } = useTypedSelector((state) => state.environment);

  // Function to handle changes in checkbox values
  const handleChange = ({
    value,
    valueKey,
  }: {
    value: boolean;
    valueKey: string;
  }) => onChange({ value, valueKey });

  // Function to parse text with links and return HTML with anchor tags
  function parseTextWithLinks(text: string) {
    // Regular expression to match markdown-style links
    // eslint-disable-next-line no-useless-escape
    const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;

    // Replace markdown-style links with anchor tags
    const parsedText = text.replace(linkRegex, function (match, text, url) {
      return `<a class=${styles.link} href="${url}" target="_blank" rel="noreferrer">${text}</a>`;
    });

    return parsedText; // Return the parsed text with HTML anchor tags
  }

  // JSX structure for rendering legal data checkboxes
  return (
    <div className={styles.container}>
      {/* Render privacy policy if available */}
      {privacy_policy && (
        <p>
          <>
            {/* Render privacy policy text with parsed links */}
            <span
              dangerouslySetInnerHTML={{
                __html: parseTextWithLinks(privacy_policy),
              }}
            />
          </>
        </p>
      )}

      {/* Render show cancellation policy checkbox if available */}
      {show_cancellation_policy && (
        <Checkbox
          value={show_cancellation_policy_checked}
          valueKey="show_cancellation_policy"
          onChange={handleChange}
          label={
            <>
              {/* Render show cancellation policy text with parsed links */}
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(show_cancellation_policy),
                }}
              />
            </>
          }
        />
      )}

      {/* Render terms and conditions checkbox if available */}
      {terms_and_conditions && (
        <Checkbox
          value={terms_and_conditions_checked}
          valueKey="terms_and_conditions"
          onChange={handleChange}
          label={
            <>
              {/* Render terms and conditions text with parsed links */}
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(terms_and_conditions),
                }}
              />
            </>
          }
        />
      )}

      {/* Render participant release agreement checkbox if available */}
      {participant_release_agreement && (
        <Checkbox
          value={participant_release_agreement_checked}
          valueKey="participant_release_agreement"
          onChange={handleChange}
          label={
            <>
              {/* Render participant release agreement text with parsed links */}
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(participant_release_agreement),
                }}
              />
            </>
          }
        />
      )}

      {/* Render supplemental terms checkbox if available */}
      {supplemental_terms && (
        <Checkbox
          value={supplemental_terms_checked}
          valueKey="supplemental_terms"
          onChange={handleChange}
          label={
            <>
              {/* Render supplemental terms text with parsed links */}
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(supplemental_terms),
                }}
              />
            </>
          }
        />
      )}

      {/* Render guest ticket contract checkbox if available */}
      {guest_ticket_contract && (
        <Checkbox
          value={guest_ticket_contract_checked}
          valueKey="guest_ticket_contract"
          onChange={handleChange}
          label={
            <>
              {/* Render guest ticket contract text with parsed links */}
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(guest_ticket_contract),
                }}
              />
            </>
          }
        />
      )}
    </div>
  );
}

// Export the LegalData component
export default LegalData;
