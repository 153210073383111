// Import createSlice function from Redux Toolkit and initEnvironment action from sessionActions
import { createSlice } from "@reduxjs/toolkit";
import { initEnvironment } from "../actions/sessionActions";

// Define a function to check and modify the payment path based on conditions
const checkPaymentPath = (payment_path: string, authorize_is_mine: string) => {
  // Define a default payment path based on the value of authorize_is_mine
  const defaultPath =
    authorize_is_mine === "false" ? "/make-payment/" : "/opaque-payment/";

  // Check if payment_path is not a string or empty, or authorize_is_mine is false
  if (
    typeof payment_path !== "string" ||
    !payment_path ||
    authorize_is_mine === "false"
  ) {
    return defaultPath;
  }

  // Modify the payment path by removing any spaces or slashes and prepend '/'
  return `/${payment_path.replace(/[\s/]/g, "")}/`;
};

// Define a function to check and modify the search item image path
const checkSearchItemImagePath = (image_path: "ship" | "cruise") => {
  // Check if the image_path is either 'ship' or 'cruise'
  const isPathValid = ["ship", "cruise"].includes(image_path);

  // If the path is valid, return the same path; otherwise, return 'ship'
  if (isPathValid) {
    return image_path;
  }

  // Default to 'ship' if the path is invalid
  return "ship";
};

// interfate from state with environment data
export interface EnvironmentState {
  primary_color: string;
  secondary_color: string;
  text_primary_color: string;
  text_secondary_color: string;
  input_border_color: string;
  input_background_color: string;
  input_placeholder_color: string;
  input_text_color: string;
  input_focus_border_color: string;
  error_color: string;
  menu_background_color: string;
  menu_text_color: string;
  element_background_primary: string;
  element_background_secondary: string;
  element_background_dark: string;
  background_color: string;
  link_color: string;
  section_background_color: string;
  shadow_color: string;
  subtext_color: string;
  delimitter_line_color: string;
  svg_icons_primary: string;
  svg_icons_secondary: string;
  search_button_background: string;
  cruise_page: number;
  site_logo: string;
  api_username: string;
  api_password: string;
  api_agency: string;
  search_domain: string;
  token_domain: string;
  pos_domain: string;
  api_domain: string;
  app_language: string;
  payment_domain: string;
  date_format: string;
  api_language: string;
  authorize_api_login_id: string;
  authorize_client_key: string;
  table_view: string;
  api_agent: string;
  authorize_is_mine: string;
  authorize_is_test: string | boolean;
  payment_api_path: string;
  search_item_image_source: "ship" | "cruise";
  privacy_policy: string;
  terms_and_conditions: string;
  participant_release_agreement: string;
  supplemental_terms: string;
  guest_ticket_contract: string;
  show_cancellation_policy: string;
  subtract_gft: boolean;
  strip_no_stop_itinerary_items: boolean;
  breadcrumbs_text: string;
  show_pricing_breakdown: string | boolean;
  check_email_uniqueness: boolean;
  pagination_type: string;
  pagination_count: number;
  isInitialized: boolean;
}

const initialState: EnvironmentState = {
  primary_color: "",
  secondary_color: "",
  text_primary_color: "",
  text_secondary_color: "",
  input_border_color: "",
  input_background_color: "",
  input_placeholder_color: "",
  input_text_color: "",
  input_focus_border_color: "",
  error_color: "",
  menu_background_color: "",
  menu_text_color: "",
  element_background_primary: "",
  element_background_secondary: "",
  element_background_dark: "",
  background_color: "",
  link_color: "",
  section_background_color: "",
  shadow_color: "",
  subtext_color: "",
  delimitter_line_color: "",
  svg_icons_primary: "",
  svg_icons_secondary: "",
  search_button_background: "",
  cruise_page: 1,
  site_logo: "",
  api_username: "",
  api_password: "",
  api_agency: "",
  search_domain: "",
  token_domain: "",
  pos_domain: "",
  api_domain: "",
  app_language: "",
  payment_domain: "",
  date_format: "",
  api_language: "",
  authorize_api_login_id: "",
  authorize_client_key: "",
  table_view: "",
  api_agent: "",
  authorize_is_mine: "",
  authorize_is_test: "",
  payment_api_path: "",
  search_item_image_source: "ship",
  privacy_policy: "",
  terms_and_conditions: "",
  participant_release_agreement: "",
  supplemental_terms: "",
  guest_ticket_contract: "",
  show_cancellation_policy: "",
  breadcrumbs_text: "",
  subtract_gft: false,
  strip_no_stop_itinerary_items: false,
  show_pricing_breakdown: false,
  check_email_uniqueness: false,
  pagination_type: "default",
  pagination_count: 10,
  isInitialized: false,
};

// Create a Redux slice for managing environment-related state
const environmentSlice = createSlice({
  name: "environment",
  initialState,
  extraReducers(builder) {
    builder.addCase(initEnvironment.fulfilled, (state, action) => {
      state.primary_color = action.payload.primary_color as string;
      state.secondary_color = action.payload.secondary_color as string;
      state.text_primary_color = action.payload.text_primary_color as string;
      state.breadcrumbs_text = action.payload.breadcrumbs_text as string;
      state.cruise_page = action.payload.cruise_page as number;
      state.site_logo = action.payload.site_logo as string;
      state.api_username = action.payload.api_username as string;
      state.api_password = action.payload.api_password as string;
      state.api_agency = action.payload.api_agency as string;
      state.search_domain = action.payload.search_domain as string;
      state.token_domain = action.payload.token_domain as string;
      state.pos_domain = action.payload.pos_domain as string;
      state.api_domain = action.payload.api_domain as string;
      state.app_language = action.payload.app_language as string;
      state.payment_domain = action.payload.payment_domain as string;
      state.date_format = action.payload.date_format as string;
      state.api_language = action.payload.api_language as string;
      state.table_view = action.payload.table_view as string;
      state.api_agent = action.payload.api_agent as string;
      state.authorize_is_mine = action.payload.authorize_is_mine as string;
      state.authorize_is_test = action.payload.authorize_is_test as string;
      state.privacy_policy = action.payload.privacy_policy as string;
      state.input_border_color = action.payload.input_border_color as string;
      state.input_text_color = action.payload.input_text_color as string;
      state.error_color = action.payload.error_color as string;
      state.menu_text_color = action.payload.menu_text_color as string;
      state.background_color = action.payload.background_color as string;
      state.link_color = action.payload.link_color as string;
      state.shadow_color = action.payload.shadow_color as string;
      state.subtext_color = action.payload.subtext_color as string;
      state.svg_icons_primary = action.payload.svg_icons_primary as string;
      state.svg_icons_secondary = action.payload.svg_icons_secondary as string;
      state.supplemental_terms = action.payload.supplemental_terms as string;
      state.subtract_gft = action.payload.subtract_gft === "true";

      state.text_secondary_color = action.payload
        .text_secondary_color as string;

      state.input_background_color = action.payload
        .input_background_color as string;

      state.input_placeholder_color = action.payload
        .input_placeholder_color as string;

      state.input_focus_border_color = action.payload
        .input_focus_border_color as string;

      state.element_background_primary = action.payload
        .element_background_primary as string;

      state.element_background_secondary = action.payload
        .element_background_secondary as string;

      state.element_background_dark = action.payload
        .element_background_dark as string;

      state.menu_background_color = action.payload
        .menu_background_color as string;

      state.section_background_color = action.payload
        .section_background_color as string;

      state.delimitter_line_color = action.payload
        .delimitter_line_color as string;

      state.search_button_background = action.payload
        .search_button_background as string;

      state.show_cancellation_policy = action.payload
        .show_cancellation_policy as string;

      state.authorize_api_login_id = action.payload
        .authorize_api_login_id as string;

      state.authorize_client_key = action.payload
        .authorize_client_key as string;

      state.terms_and_conditions = action.payload
        .terms_and_conditions as string;

      state.participant_release_agreement = action.payload
        .participant_release_agreement as string;

      state.guest_ticket_contract = action.payload
        .guest_ticket_contract as string;

      state.payment_api_path = checkPaymentPath(
        action.payload.payment_api_path as string,
        action.payload.authorize_is_mine as string,
      );

      state.search_item_image_source = checkSearchItemImagePath(
        action.payload.search_item_image_source as "ship" | "cruise",
      );

      state.strip_no_stop_itinerary_items =
        action.payload.strip_no_stop_itinerary_items === "true";

      state.show_pricing_breakdown =
        action.payload.show_pricing_breakdown === "true" ||
        action.payload.show_pricing_breakdown === true;

      state.check_email_uniqueness =
        action.payload.check_email_uniqueness === "true" ||
        action.payload.check_email_uniqueness === true;

      state.pagination_type = action.payload.pagination_type as string;
      state.pagination_count = isNaN(Number(action.payload.pagination_count))
        ? 10
        : Number(action.payload.pagination_count);
      state.isInitialized = true;
    });
  },

  reducers: {},
});

// Export the reducer function generated by createSlice
export default environmentSlice.reducer;
