import { useEffect } from "react"; // Importing the useEffect hook from React for handling side effects.

import { updateSessionKey, updateToken } from "../../store/slices/sessionSlice"; // Importing action creators and custom hooks from the Redux store.
import { useTypedDispatch, useTypedSelector } from "../../store/store"; // Importing custom hooks for accessing typed dispatch and selectors from the Redux store.
import useCookies from "../../hooks/useCookies"; // Importing the useCookies custom hook for managing cookies.

import {
  initApiToken,
  initEnvironment,
  initPosSession,
} from "../../store/actions/sessionActions"; // Importing action creators for initializing session-related variables.

function SessionGuard({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch(); // Accessing the typed dispatch function from the Redux store.
  const { getCookie, setCookie } = useCookies(); // Destructuring getCookie and setCookie functions from the useCookies hook.

  // Using typed selectors to access session and environment variables from the Redux store.
  const { isInitialized } = useTypedSelector((state) => state.environment);
  const { token, sessionKey } = useTypedSelector((state) => state.session);

  // Retrieving token and sessionKey from cookies.
  const cookieToken = getCookie("token");
  const cookieSessionKey = getCookie("sessionKey");

  // Function to initialize environment variables when the component mounts.
  const initEnvironmentVariables = () => {
    dispatch(initEnvironment());
  };

  // Function to initialize the API token when the environment is initialized.
  const initToken = () => {
    if (cookieToken) {
      dispatch(updateToken(cookieToken));
    } else {
      dispatch(initApiToken());
    }
  };

  // Function to initialize the agent session key.
  const initAgentSession = () => {
    dispatch(updateSessionKey(cookieSessionKey));
  };

  // Effect to initialize environment variables when the component mounts.
  useEffect(initEnvironmentVariables, []);

  // Effect to initialize the API token when the environment is initialized.
  useEffect(() => {
    if (isInitialized) {
      initToken();
    }
  }, [isInitialized]);

  // Effect to initialize the agent session key when the component mounts.
  useEffect(() => {
    initAgentSession();
  }, []);

  // Effect to initialize the POS session when the token or session key changes.
  useEffect(() => {
    if (token && !sessionKey) {
      dispatch(initPosSession());
    }
  }, [token, sessionKey]);

  // Effect to set the token in the cookie when it changes.
  useEffect(() => {
    if (token && !cookieToken) {
      setCookie("token", token, 3600);
    }
  }, [token, cookieToken]);

  // Effect to set the session key in the cookie when it changes.
  useEffect(() => {
    if (sessionKey && !cookieSessionKey) {
      setCookie("sessionKey", sessionKey, 3600);
    }
  }, [sessionKey, cookieSessionKey]);

  // Rendering the children components wrapped by the SessionGuard.
  return <>{children}</>;
}

// Exporting the SessionGuard component for use in other parts of the application.
export default SessionGuard;
