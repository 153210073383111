import dayjs from "dayjs";

// Custom hook for handling browser cookies.
/* eslint-disable no-unreachable-loop */
const useCookies = () => {
  // Retrieve all cookies from the document
  const cookie = document.cookie;

  /**
   * Retrieves the value of a specific cookie by name.
   * @param {string} name - The name of the cookie to retrieve.
   * @returns The value of the specified cookie, or an empty string if the cookie is not found.
   */
  const getCookie = (name: string) => {
    const nameEQ = name + "=";

    // Split and trim the document cookie string to extract individual cookies
    const cookies = document.cookie.split(";").map((c) => c.trim());

    // Find the cookie with the specified name
    const cookie = [cookies.find((c) => c.startsWith(nameEQ)) ?? ""];

    for (const c of cookie) {
      const char = c;

      if (char.startsWith(nameEQ)) {
        // Extract and return the value of the cookie
        return char.substring(nameEQ.length, c.length);
      }

      return "";
    }

    return "";
  };

  /**
   * Sets a new cookie with the specified name, value, and expiration time.
   * @param {string} name - The name of the cookie to set.
   * @param {string} value - The value to assign to the cookie.
   * @param {number} seconds - The number of seconds until the cookie expires.
   */
  const setCookie = (name: string, value: string, seconds: number) => {
    // Calculate the expiration time in UTC format using Day.js
    const utcDate = dayjs().add(seconds, "seconds").toDate().toUTCString();

    // Set the cookie in the document with the specified name, value, and expiration time
    document.cookie = `${name}=${value}; expires=${utcDate}; secure; path=/`;
  };

  return { cookie, getCookie, setCookie };
};

// Export the useCookies component
export default useCookies;
