import styles from "./index.module.scss"; // Importing SCSS module for styling.

function AuthLayout({ children }: React.PropsWithChildren) {
  // Functional component for rendering an authentication layout with children components.
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

// Exporting the AuthLayout component for use in other parts of the application.
export default AuthLayout;
