// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Root_container__xD0NI {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.Root_content__clckU {
  width: 100%;
  min-height: 100vh;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Root/index.module.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AAFF;;AAKA;EACE,WAAA;EACA,iBAAA;EACA,SAAA;AAFF","sourcesContent":["@import \"../../../styles/variables.scss\";\n@import \"../../../styles/mixins.scss\";\n\n.container {\n  width: 100vw;\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n}\n\n.content {\n  width: 100%;\n  min-height: 100vh;\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Root_container__xD0NI`,
	"content": `Root_content__clckU`
};
export default ___CSS_LOADER_EXPORT___;
