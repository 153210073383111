import { useState } from "react"; // Import React state hook

import { useLazySearchLocationsQuery } from "../store/services/SearchService"; // Import lazy query to fetch search locations
import { useLazySearchAgenciesQuery } from "../store/services/AgencyService"; // Import lazy query to fetch search agencies

// Define interface for state which store locations
interface ISearchInstance {
  results: Array<Record<string, string>>;
  isLoading: boolean;
}

// Custom hook for handling search functionality with lazy-loaded results for locations and agencies.
const useSearch = () => {
  // Use lazy search queries from respective services
  const [searchLocations] = useLazySearchLocationsQuery();
  const [searchAgencies] = useLazySearchAgenciesQuery();

  // Define state for location search results and loading state
  const [locations, setLocations] = useState<ISearchInstance>({
    results: [],
    isLoading: false,
  });

  // Define state for agency search results and loading state
  const [agencies, setAgencies] = useState<ISearchInstance>({
    results: [],
    isLoading: false,
  });

  // Function for conducting location search
  const locationSearch = async (query: string) => {
    if (query) {
      // Set loading state to true before fetching results
      setLocations((prev) => ({ ...prev, isLoading: true }));

      // Fetch search results for locations
      const { data } = await searchLocations("");

      // Filter results based on query
      const results = (data?.auto_complete_items ?? []).filter((el) =>
        el.name.toLowerCase().startsWith(query.toLowerCase()),
      );

      // Update state with search results and set loading state to false
      setLocations({
        results,
        isLoading: false,
      });
    }
  };

  // Function for conducting agency search
  const agencySearch = async (query: string) => {
    if (query) {
      // Set loading state to true before fetching results
      setAgencies((prev) => ({ ...prev, isLoading: true }));

      // Fetch search results for agencies
      const { data } = await searchAgencies(null);

      // Filter results based on query
      const results = (data ?? []).filter((el) =>
        el.name.toLowerCase().startsWith(query.toLowerCase()),
      );

      // Update state with search results and set loading state to false
      setAgencies({
        results,
        isLoading: false,
      });
    }
  };

  // Function for resetting location search results
  const locationReset = () =>
    setLocations((prev) => ({ ...prev, results: [] }));

  // Function for resetting agency search results
  const agenciesReset = () => setAgencies((prev) => ({ ...prev, results: [] }));

  // Return an object containing states and functions for location and agency searches
  return {
    locations,
    agencies,
    searchHandlers: { locationSearch, agencySearch },
    resetHandlers: { locationReset, agenciesReset },
  };
};

// Export the useScript component
export default useSearch;
