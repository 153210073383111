import { useMemo } from "react"; // Import React hook for memoization
import classNames from "classnames"; // Import classNames utility for managing CSS classes
import Flatpickr from "react-flatpickr"; // Import Flatpickr date picker component
import { nanoid } from "@reduxjs/toolkit"; // Import unique ID generator from Redux Toolkit
import { useTranslation } from "react-i18next"; // Import translation hook from react-i18next

// Import Flatpickr plugins for month selection and scrolling
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import scrollDatePlugin from "flatpickr/dist/plugins/scrollPlugin";

import { Plugin } from "flatpickr/dist/types/options"; // Import Plugin type from Flatpickr
import dayjs, { Dayjs } from "dayjs"; // Import dayjs library for date manipulation

// Import additional styles for Flatpickr and its plugins
import "flatpickr/dist/themes/light.css";
import "flatpickr/dist/plugins/monthSelect/style.css";

// Import component styles
import styles from "./index.module.scss";
import "./index.scss";

// Props interface for the DatePicker component.
interface IDatePickerProps {
  defaultDate?: string[];
  valueKey?: string;
  returnFormat?: string;
  minDate?: Dayjs;
  mode?: "time" | "multiple" | "range" | "single";
  select?: "month" | "day";
  label?: string;
  errorMessage?: string;
  placeholder: string;
  name?: string;
  isRequired?: boolean;
  className?: string;
  onChange?: ({
    value,
    valueKey,
  }: {
    value: string[];
    valueKey?: string;
  }) => void;
}

// Initialize monthSelect plugin for Flatpickr with specified options
const monthSelect: Plugin = new (monthSelectPlugin as any)({
  dateFormat: "m-Y",
  theme: "light",
});

// Initialize scrollDate plugin for Flatpickr with specified options
const scrollDate: Plugin = new (scrollDatePlugin as any)({
  dateFormat: "m-Y",
  theme: "light",
});

// Object linking select type to corresponding Flatpickr plugin
const datePluginsLinking = {
  month: monthSelect, // Link 'month' select type to monthSelect plugin
  day: scrollDate, // Link 'day' select type to scrollDate plugin
};

/**
 * Custom date picker component.
 * @param {string[]} [defaultDate] - Default selected date(s).
 * @param {string} [valueKey] - Key associated with the selected value.
 * @param {string} [returnFormat] - Format for the returned date(s).
 * @param {Dayjs} [minDate] - Minimum selectable date.
 * @param {"time" | "multiple" | "range" | "single"} [mode="range"] - Date picker mode.
 * @param {"month" | "day"} [select="month"] - Select type (month/day).
 * @param {string} [label] - Label for the date picker.
 * @param {string} [errorMessage] - Error message to display.
 * @param {string} placeholder - Placeholder text for the date picker.
 * @param {string} [name] - Name attribute for the input element.
 * @param {boolean} [isRequired=false] - Indicates if the date picker is required.
 * @param {string} [className] - Custom class name for styling.
 * @param {Function} [onChange] - Function called when the date selection changes.
 * @returns {JSX.Element} Custom date picker component.
 */
function DatePicker({
  defaultDate,
  valueKey,
  returnFormat,
  minDate,
  mode,
  select,
  label,
  errorMessage,
  placeholder,
  name,
  isRequired,
  className,
  onChange,
}: IDatePickerProps) {
  const { t } = useTranslation(); // Translation hook from react-i18next

  // Generate unique IDs for key and plugin
  const id = useMemo(nanoid, []);
  const pickerKey = useMemo(nanoid, [defaultDate]);
  const pickerPlugin = useMemo(() => datePluginsLinking[select ?? "month"], []);

  // Check if default date is provided
  const isDefaultDateProvided = useMemo(
    () => defaultDate && defaultDate?.filter((el) => el).length > 0,
    [defaultDate],
  );

  // Handle date change
  const handleDateChange = (newDate: Date[]) => {
    const value = newDate.map((date) => {
      const formattedNow = dayjs().format(returnFormat);
      const formattedDate = dayjs(formattedNow, { format: returnFormat }, true);
      const isDateValid = formattedDate.isValid();

      if (isDateValid) {
        return dayjs(date).format(returnFormat);
      } else {
        return dayjs(date).format("MM/DD/YYYY");
      }
    });

    if (onChange && (mode !== "range" || value.length > 1)) {
      onChange({ value, valueKey });
    }
  };

  return (
    <div className={classNames(styles.container, className)}>
      {/* Render label with optional asterisk for required fields */}
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      {/* Render Flatpickr date picker */}
      <Flatpickr
        key={pickerKey}
        name={name}
        className={classNames(styles.picker, {
          [styles.picker_error]: !!errorMessage,
        })}
        type="text"
        options={{
          mode,
          minDate: minDate ? minDate.toDate() : undefined,
          plugins: [pickerPlugin],
          defaultDate: isDefaultDateProvided
            ? defaultDate?.map((el) => dayjs(el).toDate())
            : defaultDate,
        }}
        placeholder={t(placeholder || "Select date")}
        onChange={handleDateChange}
      />

      <span className={styles.error}>{errorMessage}</span>
    </div>
  );
}

// Default props for DatePicker component
DatePicker.defaultProps = {
  defaultDate: [],
  valueKey: "",
  returnFormat: "",
  mode: "range",
  select: "month",
  label: "",
  errorMessage: "",
  placeholder: "",
  name: "",
  isRequired: false,
  className: "",
  onChange: () => {},
};

// Export the DatePicker component
export default DatePicker;
