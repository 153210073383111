import { useState, useRef, useMemo, useEffect } from "react"; // Import React hooks
import classNames from "classnames"; // Import classNames utility for managing CSS classes

import useOnClickOutside from "../../../hooks/useClickOutside"; // Import custom hook for handling click outside events

import styles from "./index.module.scss"; // Import component styles

// Interface for the CustomSelect component.
interface ISelectProps {
  className?: string;
  value: string;
  valueKey: string;
  items: Array<{ value: string; label: string }>;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  isRequired?: boolean;
  onChange: ({ value, valueKey }: { value: string; valueKey: string }) => void;
}

/**
 * Custom select component.
 *
 * @param {string} [className] - Custom class name for styling.
 * @param {string} value - The currently selected value.
 * @param {string} valueKey - The key associated with the selected value.
 * @param {Array<{ value: string; label: string }>} items - Array of select options.
 * @param {string} [label] - Label for the select input.
 * @param {string} [placeholder] - Placeholder text for the select input.
 * @param {string} [errorMessage] - Error message to display.
 * @param {boolean} [isRequired] - Indicates if the select input is required.
 * @param {Function} onChange - Function called when the select value changes.
 * @returns {JSX.Element} Custom select component.
 */
function CustomSelect({
  className,
  value,
  valueKey,
  items,
  label,
  placeholder,
  errorMessage,
  isRequired,
  onChange,
}: ISelectProps) {
  // State to manage the visibility of the select dropdown
  const [isVisible, setIsVisible] = useState(false);

  // State to manage the internal value of the select
  const [internalValue, setInternalValue] = useState(value ?? "");

  // Effect to synchronize the internal value with the provided value
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  // Memoized active option based on the current value
  const activeOption = useMemo(
    () => items.find((el) => el.value === value),
    [value],
  );

  // Ref for detecting clicks outside the select component
  const outsideRef = useRef(null);

  // Handler for changing the select value
  const handleChange = (newValue: string) => () => {
    setIsVisible(false);
    setInternalValue(newValue);

    if (onChange) {
      onChange({ value: newValue, valueKey });
    }
  };

  // Hook to handle clicks outside the select component
  useOnClickOutside(outsideRef, () => setIsVisible(false));

  return (
    <div className={styles.container}>
      {/* Render label with optional asterisk for required fields */}
      {label && (
        <span className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </span>
      )}

      {/* Render select field container */}
      <div ref={outsideRef} className={styles.fieldContainer}>
        {/* Render select field */}
        <div
          className={classNames(styles.field, className, {
            [styles.field_error]: !!errorMessage,
          })}
          onClick={() => setIsVisible((prev) => !prev)}>
          {/* Render selected value or placeholder */}
          <span
            className={classNames(styles.value, {
              [styles.placeholder]: !value,
              [styles.value_visible]: isVisible,
            })}>
            {activeOption?.label ?? placeholder}
          </span>
        </div>

        {/* Render select dropdown */}
        <div
          className={classNames(styles.items, {
            [styles.items_visible]: isVisible,
          })}>
          {/* Render select options */}
          {items.map((item) => {
            const isChosen = internalValue === item.value;

            return (
              <div
                key={item.value}
                className={classNames(styles.item, {
                  [styles.item_chosen]: isChosen,
                })}
                onClick={handleChange(item.value)}>
                <span>{item.label}</span>
              </div>
            );
          })}
        </div>
      </div>

      <span className={styles.error}>{errorMessage}</span>
    </div>
  );
}

// Default props for CustomSelect component
CustomSelect.defaultProps = {
  className: "",
  valueKey: "",
  label: "",
  placeholder: "",
  errorMessage: "",
  isRequired: false,
  onChange: () => {},
};

// Export the CustomLink component
export default CustomSelect;
