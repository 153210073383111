// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingContainer_container__\\+Wmf6 {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/containers/LoadingContainer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;AACF","sourcesContent":[".container {\n  width: 100%;\n  min-height: 100vh;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LoadingContainer_container__+Wmf6`
};
export default ___CSS_LOADER_EXPORT___;
