import { useMemo, useState, useEffect } from "react"; // Importing necessary hooks from React
import { nanoid } from "@reduxjs/toolkit"; // Importing nanoid from Redux Toolkit for generating unique IDs
import { useTranslation } from "react-i18next"; // Importing the useTranslation hook for language translation
import { useSearchParams } from "react-router-dom"; // Importing the useSearchParams hook from React Router DOM

import { useTypedSelector } from "../../../../../store/store"; // Importing the useTypedSelector hook from the Redux store

import Collapsible from "../../../../shared/Collapsible"; // Importing the Collapsible component
import StateroomPriceBreakdown from "../StateroomPriceBreakdown"; // Importing the StateroomPriceBreakdown component

import styles from "./index.module.scss"; // Importing the CSS module for styling

// Functional component for rendering completed staterooms.
function CompletedStaterooms() {
  const { t } = useTranslation(); // Initializing the useTranslation hook for translation

  const [searchParams] = useSearchParams(); // Destructuring and initializing the useSearchParams hook for accessing URL search parameters
  const currentRoomNumber = +(searchParams.get("room") ?? 0); // Extracting the current room number from URL search parameters

  // Accessing rooms and show_pricing_breakdown state from Redux store using the useTypedSelector hook
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { show_pricing_breakdown } = useTypedSelector(
    (state) => state.environment,
  );

  // State to manage the expanded state of each stateroom
  const [isExpanded, setIsExpanded] = useState<Record<number, boolean>>({});

  // Memoizing the selected room based on the current room number and rooms state
  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }
  }, [rooms, currentRoomNumber]);

  // Function to update the expanded state of a stateroom
  const updateIsExpanded = (index: number) => {
    setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  // Effect to expand the stateroom when a grade is available
  useEffect(() => {
    if (room?.grade) {
      setIsExpanded(() => ({ [currentRoomNumber - 1]: true }));
    }
  }, [room?.grade]);

  return (
    <div className={styles.container}>
      {/* Mapping over each stateroom and rendering a Collapsible component */}
      {Object.values(rooms ?? {}).map((stateroom, index) => {
        return (
          <Collapsible
            expanded={isExpanded[index]} // Setting the expanded state of the Collapsible component
            onExpand={() => updateIsExpanded(index)} // Handling expand/collapse action
            key={nanoid()} // Assigning a unique key to each Collapsible component
            renderHeader={() => (
              // Rendering the header of the Collapsible component
              <p
                className={styles.header}>{`${t("stateroom")} ${index + 1}`}</p>
            )}>
            {/* Rendering the body of the Collapsible component */}
            <div className={styles.body}>
              {/* Rendering the grade name if available */}
              {stateroom.grade && (
                <p className={styles.gradeName}>{stateroom.grade.name}</p>
              )}

              {/* Rendering the fare name */}
              <div className={styles.textBlock}>
                <span>{`${t("farename")}:`}</span>
                <span>{stateroom.fare?.rate_name ?? "N/A"}</span>
              </div>

              {/* Rendering the StateroomPriceBreakdown component if pricing breakdown is enabled */}
              {show_pricing_breakdown && (
                <StateroomPriceBreakdown roomNumber={index + 1} />
              )}
            </div>
          </Collapsible>
        );
      })}
    </div>
  );
}

// Exporting the CompletedStaterooms component as default.
export default CompletedStaterooms;
