import dayjs from "dayjs";

/**
 * Formats a URL by adding start_date and end_date query parameters if they are not present.
 * @param {string} url - The original URL string.
 * @returns {string} The formatted URL string.
 */
function formatUrl(url: string) {
  const currentUrl = window.location.href;
  const currentUrlObj = new URL(currentUrl);
  const protocolAndHost = currentUrlObj.protocol + "//" + currentUrlObj.host;
  const urlWithoutHash = url.replace("#/", "");
  const urlObj = new URL(protocolAndHost + urlWithoutHash);
  const searchParams = urlObj.searchParams;

  let normalizedUrl = (urlObj.pathname.slice(0, -1) + urlObj.search)
    .toString()
    .replace(/\/+/g, "/");

  if (!searchParams.has("start_date")) {
    const formattedStartDate = dayjs().format("YYYY-MM");

    searchParams.set("start_date", formattedStartDate);
  }

  if (!searchParams.has("end_date")) {
    const formattedEndDate = dayjs().add(1, "year").format("YYYY-MM");

    searchParams.set("end_date", formattedEndDate);
  }

  if (url.includes("#/")) {
    normalizedUrl += "#/";
  }

  return normalizedUrl;
}

export default formatUrl;
