// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToastContainer_container__4SjIS {
  width: 100vw;
  min-height: 100vh;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/containers/ToastContainer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".container {\n  width: 100vw;\n  min-height: 100vh;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ToastContainer_container__4SjIS`
};
export default ___CSS_LOADER_EXPORT___;
